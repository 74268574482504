import { computed, reactive, watchEffect, ref, nextTick } from 'vue';
import _ from 'lodash/object'
export default (props, instance, options = {}) => {

    //todo: this whole thing is redundant. However, it allows composition to react to component data. keep for rerefence
    let paginationData = reactive({
        pages           : 0,
        currentPage     : 0,
        pageSize        : 0,
        lastPage        : 0,
        firstPage       : 0,
        links : [],

    });


    let getBaseLink = (to = 0, countPages = 0, current = 0,) => {
        return  {
            to              : to,
            isToFirst       : to === 0,
            isToLast        : to === countPages,
            isToNext        : to === current + 1,
            isToDoubleNext  : to === current + 2,
            isToPrev        : to === current - 1,
            isToDoublePrev  : to === current - 2,
            isActive        : to === current,
        };
    };
    /**
     * Method to compute and update the ractive pagination data that we provide
     * @param pageSize
     * @param total
     * @param currentPage
     */
    let updatePagination = (pageSize, total, currentPage) => {
        paginationData.pages = Math.ceil(total/pageSize);
        pagination.currentPage = currentPage;
        pagination.pageSize = pageSize;
        pagination.firstPage = 0;
        pagination.lastPage = Math.ceil(total/pageSize);

        let link = {
            to: 0,
            isToFirst: this.to === 0,
            isToLast: false,
            isToNext: false,
            isToPrev: false,
            isActive: false,
        };

        let links = [];
        // add the to start link
        links.push({})
        // add the to prev link

        // add the prev literal link, if available
        // add the 2xPrevliteral link if available

        // add the current link

        // add the next literal link, if available
        // add the 2xNext literal link if available

        // add the to next link

        // add the to last link

        console.log('can update the damn pagination now...', pageSize, total, currentPage);
    };

    /**
     * Method to watch relevant data in order to reactively updatePagination()
     */
    let setup = () => {
        let listPath        = options.listPath        || 'data.listData.items';
        let totalPath       = options.totalPath       || 'data.listData.totalCount';
        let currentPagePath = options.currentPagePath || 'data.listData.paginationStart';

        watchEffect(() => {
            let items       = ref(_.get(instance, listPath));
            let total       = ref(_.get(instance, totalPath));
            let currentPage = ref(_.get(instance, currentPagePath));
            let pageSize    = ref(items.value ? items.value.length : ref(0));

            if (typeof pageSize.value !== 'number' || typeof total.value !== 'number' || typeof currentPage.value !== 'number') {
                return;
            }
            updatePagination(pageSize.value, total.value, currentPage.value);

        });
    };

    /**
     * register the setup once the component is read, we can make sure by waiting for one tick
     */
    nextTick(setup).then(()=>{});

    return {
        paginationData
    };
}
